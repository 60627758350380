import React from 'react';
import Layout from '../../components/Layout';

import StandardCreateEdit from "../../components/standards/StandardsCreateEdit";

export default function CreateStandard() {

    return (
        <Layout allowAnonymous={false}>
            <StandardCreateEdit type={"create"} style={{minWidth:700, width:"60%", marginLeft: "auto", marginRight: "auto"}}/>
        </Layout>
    )
}